import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import { useApp } from 'components/app';
import { Button, Input, RadioBox, SelectInput } from 'components/common/atoms';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';

import { IOptions } from 'interface/ISelect';
import { ICampaignIndustries } from 'interface';
import { PublishContext } from 'context/publish/publish.provider';
import { IPublishType } from 'context/publish/publish.reducer';
import { useRouter } from 'next/router';
import { ILocationFull } from 'interface/ILocation';
import Navbar from '../Navbar';
import { JWT, TPSAPI } from '@api/users';
import { getTime, differenceInDays } from 'date-fns';
import useOnboardingContext from 'context/OnboardingContext';
import { useMondayContext } from 'context/monday/monday.reducer';
import { v4 as uuidv4 } from 'uuid';

const optionsRadio = [
  { value: 'HyperLocal', label: 'HyperLocal', checked: true },
  { value: 'Bulk Buying', label: 'Bulk Buying', checked: false },
];
type IProps = {
  industries: ICampaignIndustries[];
  screen?: ILocationFull;
};

const PublishBasic: React.FC<IProps> = ({ industries, screen }: IProps) => {
  const intl = useIntl();
  const router = useRouter();
  const { notify } = useApp();
  const [startDate, setStartDate] = useState<string | null>(null);

  const { publishState, dispatch } = React.useContext(PublishContext);
  const { initialState, state, dispatch: mondayDispatch } = useMondayContext();
  const [name, setName] = useState<string>(publishState.name || 'New Campaign');
  const [industry, setIndustry] = useState<IOptions | undefined>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const {
    setState,
    state: { run, tourActive, stepIndex, steps },
  } = useOnboardingContext();

  useEffect(() => {
    if (tourActive) {
      if (stepIndex < 3) {
        setState({ run: true, stepIndex: 3, steps, tourActive });
      } else {
        setState({ run: true, stepIndex: 4, steps, tourActive });
      }
    }
  }, []);

  useEffect(() => {
    let user = JWT.getJwtUser();
    console.log('user', user);
    if (user.role === 'admin') setIsAdmin(true);
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '3' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: '*Campaign name is mandatory!',
          id: 'Error.mandatoryFieldd',
        })
      ),

    industry: Yup.string().required(
      intl.formatMessage({
        defaultMessage: '*Industry field is mandatory!',
        id: 'Error.mandatoryFieldd',
      })
    ),
  });

  const useYupValidationResolver = (schema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await schema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schema]
    );

  const resolver = useYupValidationResolver(validationSchema);

  const [options, setOptions] = useState<IOptions[] | null>(null);
  const getDefaultCampaignName = () => {
    const { name: campaignName } = publishState;
    const { campaign_prefix: prefixSetOnUser } = JWT.getJwtUser();

    if (prefixSetOnUser) {
      const prefixCampaign = campaignName.substring(0, prefixSetOnUser?.length);
      if (prefixCampaign === prefixSetOnUser) {
        return campaignName;
      } else {
        return prefixSetOnUser + ' ' + campaignName;
      }
    } else {
      if (campaignName.startsWith('[MANAGED]') && router.query.cId) {
        return campaignName.replace('[MANAGED] ', '');
      } else {
        return campaignName;
      }
    }
  };
  React.useEffect(() => {
    screen &&
      notify(
        'Fill in the data and click on Continue.',
        'success',
        'top-right',
        'The screen was added to you campaign. '
      );
  }, [screen]);
  React.useEffect(() => {
    if (industries) {
      const optionMap: IOptions[] = industries.map(el => {
        const e: IOptions = {
          value: `${el.id}`,
          label: el.label,
        };
        if (publishState.industry) {
          if (publishState.industry === `${el.label}`) {
            setIndustry(e);
            setValue('industry', publishState.industry);
          }
        }
        return e;
      });
      setOptions(optionMap);
      if (publishState.industry) {
      }
    }
    return () => {};
  }, [industries]);

  const { register, handleSubmit, formState, setValue, control } = useForm<{ name: string; industry: string }>({
    reValidateMode: 'onBlur',
    mode: 'onTouched',
    resolver,
    defaultValues: {
      name: '',
      industry: publishState.industry ? publishState.industry : '',
    },
  });
  useEffect(() => {
    //for
    const nameValue: string | undefined = getDefaultCampaignName();
    setValue('name', nameValue);
    if (publishState.industry) {
      setIndustry({ value: publishState.industry, label: publishState.industry });
      setValue('industry', publishState.industry);
    }
  }, [publishState]);

  const { dirtyFields, errors } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };

  const onSubmit = async (data: any) => {
    if (data.campType === 'Bulk Buying') {
      const uuid = uuidv4();
      mondayDispatch({
        type: 'setBasic',
        payload: {
          uuid,
          name: data.name,
          step: 1,
          industry: data.industry.label || data.industry,
          type: 'BulkBuying',
        },
      });
      await TPSAPI.submitCampaign({
        ...initialState,
        step: 1,
        mondayCampaigns: [],
        uuid: uuid,
        name: data.name,
        industry: data.industry.label || data.industry,
        type: 'BulkBuying',
      });

      router.push(`/bulk-buying/brief/${uuid}`).catch(err => console.log(err));
    } else {
      let date = JWT.getJwtUser().role === 'admin' ? 0 : 2;
      const customDate = startDate;
      // calculate diff in days
      if (customDate) {
        const currentDate = new Date();

        const tempDate = new Date(customDate);

        const differenceDays = differenceInDays(tempDate, currentDate);
        date = differenceDays;
      }

      dispatch({
        type: IPublishType.setBasic,
        payload: {
          name: data.name,
          date: date,
          customDate,
          industry: data.industry.label || data.industry,
          type: 'HyperLocal',
        },
      });
      router.push(`/publish/filters/?campaignId=${publishState.uuid}`);
      // router.push(`/publish/select/?campaignId=${publishState.uuid}`);
    }
  };

  return (
    <div className="flex flex-col min-h-screen page-section">
      <Navbar title="Publish" subtitle={name} backBtn="/" hideAddBtn={true} />
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-1 pb-20 justify-evenly">
        <div className="flex items-center h-50">
          <img src="/images/publish-start.svg" loading="lazy" className="block m-auto dark:hidden" />
          <img src="/images/publish-start-dark.svg" loading="lazy" className="hidden m-auto dark:block" />
        </div>
        <div className="flex-col items-center justify-center mx-auto md:flex md:max-w-md lg:h-100-80 ">
          <div id="campaignForm" className="w-full">
            <Input
              name="name"
              data-cy="basicName"
              innerRef={register}
              label="Campaign name"
              validation={getValidation('name')}
              onChange={(event: any) => {
                setName(event.target.value);
              }}
            />
            {options && (
              <Controller
                control={control}
                name="industry"
                defaultValue={industry?.label}
                render={({ onChange, onBlur, value, ref }, { invalid, isTouched, isDirty }) => (
                  <SelectInput
                    options={options}
                    defaultValue={industry || undefined}
                    onChange={e => {
                      setIndustry(e);
                      onChange(e.label);
                    }}
                    key={industry?.value}
                    label="Industry"
                    validation={getValidation('industry')}
                    data-cy="basicIndustry"
                    dataCy="basicIndustry"
                    isMulti={false}
                    fullWidth={true}
                    isClearable={false}
                    selName="industry"
                  />
                )}
              />
            )}
            {isAdmin && Object.keys(router.query).length === 0 && (
              <Controller
                control={control}
                name="campType"
                defaultValue={optionsRadio[0]}
                render={({ onChange, onBlur, value, ref }, { invalid, isTouched, isDirty }) => (
                  <SelectInput
                    options={optionsRadio}
                    onChange={e => {
                      onChange(e.label);
                    }}
                    label="Campaign Type"
                    defaultValue={optionsRadio[0]}
                    isMulti={false}
                    fullWidth={true}
                    isClearable={false}
                    selName="campType"
                  />
                )}
              />
            )}
            {isAdmin && (
              <Input
                type="date"
                name="startdate"
                data-cy="startdate"
                innerRef={register}
                label="Start date"
                onChange={(event: any) => {
                  setStartDate(event.target.value);
                }}
              />
            )}
          </div>
          {/* {screen && (
              <div className="w-full space-y-16">
                <div
                  className="px-4 py-3 text-teal-900 bg-green-100 border-t-4 border-green-500 rounded-b shadow-md"
                  role="alert"
                >
                  <div className="flex">
                    <div className="py-1">
                      <svg
                        className="w-6 h-6 mr-4 text-green-500 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                      </svg>
                    </div>
                    <div>
                      <p className="font-bold">The screen was added to you campaign</p>
                      <p className="text-sm">Fill in the data and click Continue</p>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          <div>
            <div className="pt-16 mx-auto w-72 pb-7">
              <Button id="continuePublishBasic" type="submit" color="primary" loading={false} fullWidth={true}>
                {intl.formatMessage({
                  defaultMessage: 'Next',
                  id: 'Button.Next',
                })}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default PublishBasic;
